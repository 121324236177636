// Client side response
import consumer from './consumer'

let intervalId

document.addEventListener('DOMContentLoaded', () => {
  // Auto-fetch every 1 hour
  intervalId = setInterval(checkUserIdCookieNull, 3600000)
})

const onlineUsersChannel = consumer.subscriptions.create('OnlineUsersChannel', {
  connected() {
    // console.log('Connected to the OnlineUsersChannel')
  },
  disconnected() {
    // console.log('Disconnected from the OnlineUsersChannel')
  },
})

function checkUserIdCookieNull() {
  const userId = getCookie('uid')

  if (userId === null) {
    onlineUsersChannel.unsubscribe()
    clearInterval(intervalId)
  }
}

function getCookie(name) {
  let cookieArr = document.cookie.split(';')

  for (const element of cookieArr) {
    let cookiePair = element.split('=')

    // Remove whitespace at the beginning of the cookie name
    let cookieName = cookiePair[0].trim()
    if (cookieName === name) {
      return decodeURIComponent(cookiePair[1])
    }
  }

  // Return null if the cookie is not found
  return null
}
